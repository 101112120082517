import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'
import { Link } from 'gatsby'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const NewMarketsOpenClickAndReserve: React.FC<Props> = ({
  data,
  location,
}: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/new-market-will-open.jpg)' }}
        ></div>
        <article className="post">
          <h1>New Markets Will open the Click and Reserve feature</h1>

          <div className="row">
            <div className="col-md-6 mb-3">
              <h3>August 2021</h3>
              <ul>
                <li>
                  <span className="flag-icon flag-icon-es mr-2"></span> Spain
                </li>
                <li>
                  <span className="flag-icon flag-icon-sg mr-2"></span>{' '}
                  Singapore
                </li>
                <li>
                  <span className="flag-icon flag-icon-de mr-2"></span> Germany
                </li>
                <li>
                  <span className="flag-icon flag-icon-it mr-2"></span> Italy
                </li>
              </ul>
            </div>
            <div className="col-md-6 mb-3">
              <h3>September 2021</h3>
              <ul>
                <li>
                  <span className="flag-icon flag-icon-mo mr-2"></span> Macao
                </li>
              </ul>
            </div>
          </div>

          <hr />
          <div className="spacer"></div>

          <h3>Already opened</h3>
          <ul>
            <li>
              <span className="flag-icon flag-icon-kr mr-2"></span> South Korea
            </li>
            <li>
              <span className="flag-icon flag-icon-ch mr-2"></span> Switzerland
            </li>
            <li>
              <span className="flag-icon flag-icon-fr mr-2"></span> France
            </li>
            <li>
              <span className="flag-icon flag-icon-gb mr-2"></span> United
              Kingdom
            </li>
            <li>
              <span className="flag-icon flag-icon-hk mr-2"></span> Hong Kong
            </li>
            <li>
              <span className="flag-icon flag-icon-my mr-2"></span> Malaysia
            </li>
            <li>
              <span className="flag-icon flag-icon-jp mr-2"></span> Japan
            </li>
          </ul>
          <p>
            For more information on Click & Reserve please{' '}
            <Link to="/01-2021/click-and-reserve/">click here</Link>
          </p>

          <div className="spacer"></div>
          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/nils-lehmann.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:nils.lehmann@longines.com">
                    nils.lehmann@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default NewMarketsOpenClickAndReserve

export const pageQuery = graphql`
  query NewMarketsOpenClickAndReserve_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
